.App {
  text-align: center;
  background-color: #04090a;
}



body {
  background-color: #04090a; /* Dark background color */
  color: #ffffff; /* Light text color */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  position: relative;
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-direction: column; 
  height: 100dvh; 
}

.Game-container {
  position: relative; 
}

.Game-image {
  width: 68vh; 
  height: 100dvh;
  margin: auto;
  display: block; /* Hiển thị ảnh là một khối đầy đủ */
}

.Play-button {
  position: absolute;
  bottom: 0;
  width: 50%;
  background: url('./button_login.png') no-repeat center / contain;
  transform: translate(-50%, -50%); 
  padding: 16px;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .Game-image {
    width: 100%;
  }
}


.Logout-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 12px;
  background-color: #f67009;
  color: #FFF;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.Purchase-button {
  position: absolute;
  top: 60px;
  right: 10px;
  padding: 12px;
  background-color: #f67009;
  color: #FFF;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.Deposit-button {
  position: absolute;
  top: 110px;
  right: 10px;
  padding: 12px;
  background-color: #f67009;
  color: #FFF;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.Export-button {
  position: absolute;
  top: 160px;
  right: 10px;
  padding: 12px;
  background-color: #f67009;
  color: #FFF;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.Login-button {
  padding: 12px;
  background-color: #f67009;
  color: #FFF;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.bg-reference-code {
  height: 100vh;
  background-color: rgb(251, 243, 230);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: dogicapixel;
  src: url('./assets/fonts/dogicapixel.ttf');
  font-weight: 400;
}

.press-start-2p-regular {
  font-family: "dogicapixel", system-ui;
  font-weight: 400;
  font-style: normal;
  color: #90351d;
  font-size: 20px;
}

.dynamic-font-size {
  font-size: 28px !important;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .dynamic-font-size {
    font-size: 36px !important;
  }
}

@media (min-width: 1200px) {
  .dynamic-font-size {
    font-size: 48px !important;
  }
}

.responsive-input {
  width: 300px !important;
  height: 50px !important;
  font-size: 32px !important;
  text-align: center !important;
  letter-spacing: 18px !important;
}

@media (max-width: 767px) {
  .responsive-input {
    font-size: 24px !important;
    width: 200px !important;
    text-align: center !important;
    letter-spacing: 10px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .responsive-input {
    font-size: 28px !important;
    width: 220px !important;
    letter-spacing: 14px !important;
    text-align: center !important;
  }
}

.Deposit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

}

.deposit-page {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 70%;
  text-align: center;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wallet-info {
  width: 50%;
  margin-bottom: 20px;
}

.wallet-address, .network {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 10px;
}

.icon {
  font-size: 1.5em;
  color: #333;
}

.address-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.network-info {
  text-align: left;
}

.network-info p {
  margin: 0;
  font-size: 1em;
}

.balance-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
  flex-wrap: wrap;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.balance-info input {
  width: 60%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.balance-info button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.deposit-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.wallet-balance-info p {
  font-size: 1em;
  color: #555;
}

@media (max-width: 768px) {
  .deposit-page {
      width: 90%;
      min-height: 60vh;
  }

  .balance-info input, .balance-info button {
      width: 100%;
      margin-top: 10px;
  }
}

.login-error-message {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 80%;
  background: url('./button_login.png') no-repeat center / contain;
  padding: 16px;
  color: #FFF;
  border: none;
  max-width: 344px;
  height: max-content;
}

.login-error-message > p {
  font-family: "dogicapixel", system-ui;
  font-weight: 400;
  font-style: normal;
  color: #90351d;
  max-width: 344px;
  font-size: 1.2rem;
}